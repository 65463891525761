// require('./bootstrap');
function notification(
    message: string,
    type: string = 'success',
    settings: any = {
        // settings
        placement: {
            from: 'top',
            align: 'right',
        },
        offset: {
            x: 20,
            y: 80,
        },
    }
) {
    settings.type = type;

    // @ts-ignore
    $.notify(
        {
            // options
            message: message,
        },
        settings
    );
}

/**
 * Save content
 */
$(() => {
    $(document).on('click', '.save-content', (e) => {
        e.preventDefault();
        let el = $(e.currentTarget);

        const getContents = (classes: string) => {
            return classes.split(',').map((value) => {
                return Array.prototype.map.call(
                    document.getElementsByClassName(value.trim()),
                    (item: any) => {
                        const i = $(item);
                        return {
                            id: i.data('id'),
                            content: i.html(),
                        };
                    }
                );
            }).flat(1);
        };

        $.ajax({
            method: 'post',
            data: {
                _token: $('#csrf-token').attr('content'),
                pageContent: getContents('ckeditor-inline p-content, inline-content'),
                globalContent: getContents('ckeditor-inline p-global-content'),
            },
            url: el.data('href'),
            success: function (response) {
                notification(
                    response.message,
                    response?.status === 'ok' ? 'success' : 'danger'
                );
            },
            error: function () {
                notification(
                    'Chyba pri vykonaní operácie. Skúste nanovo načítať stránku.',
                    'danger'
                );
            },
        });
    });
});

/**
 * Auto image replacement on error
 */
$(() => {
    $(document).on('error', 'img', (e) => {
        $(e.currentTarget).attr('src', '/images/no-image.png');
    });
});

/**
 * Photo slideshow
 */
$(() => {
    // @ts-ignore
    if (typeof $.fn.magnificPopup === 'function') {
        // @ts-ignore
        $('*').magnificPopup({
            delegate: 'a.magnific',
            type: 'image',
            gallery: {
                enabled: true, // set to true to enable gallery

                preload: [0, 2], // read about this option in next Lazy-loading section

                navigateByImgClick: true,

                arrowMarkup:
                    '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

                tPrev: 'Predošlá (ľavá šípka)', // title for left button
                tNext: 'Nasledujúca (pravá šípka)', // title for right button
                tCounter: '<span class="mfp-counter">%curr% z %total%</span>', // markup of counter
            },
        });
    }
});

/**
 * Catch deleting an object
 */
const deleteElements = document.getElementsByClassName('delete');
for (let i = 0; i < deleteElements.length; i++) {
    const el = deleteElements.item(i);
    el.addEventListener('click', (e) => {
        e.stopPropagation();
        if (confirm('Naozaj si prajete zmazať záznam?')) {
            // continue
        } else {
            e.preventDefault();
        }

        return 0;
    });
}

/**
 * Gallery upload
 */
$(() => {
    // @ts-ignore
    const el = $('#gallery-upload');
    // @ts-ignore
    if (typeof el.fileinput === 'function') {
        // @ts-ignore
        el.fileinput({
            uploadUrl: el.data('url'), // server upload action
            uploadExtraData: {
                _token: $('#csrf-token').attr('content'),
            },
            uploadAsync: true,
            minFileCount: 1,
            maxFileCount: 20,
            multiple: true,
            allowedFileExtensions: ['jpg', 'jpeg', 'png', 'gif'],
            language: 'sk',
        });

        $('.file-input').hide();
        $('#add-photos').on('click', () => {
            $('.file-input').toggle();
            $('#gallery-helper').toggle();
        });
    }
});

/**
 * Gallery delete photo
 */
$(() => {
    $('.delete-photo').on('click', (e) => {
        e.preventDefault();
        let el = $(e.currentTarget);

        if (confirm('Naozaj si prajete zmazať záznam?')) {
            // continue
        } else {
            return;
        }

        $.ajax({
            method: 'post',
            data: {
                _token: $('#csrf-token').attr('content'),
            },
            url: el.data('href'),
            success: function () {
                el.parents('li').remove();
            },
            error: function () {
                alert('Fotografiu sa napodarilo zmazať');
            },
        });
    });
});

/**
 * Gallery reorder
 */
$(() => {
    $('#reorder-helper').hide();
    $('#reorder-photos').on('click', () => {
        $('#reorder-helper').show();
        $('ul.photos-list').sortable({ tolerance: 'pointer' });
        $('#reorder-photos').hide();
        $('#save-reorder').show();
        $('.image-link')
            .attr('onclick', 'event.preventDefault();javascript:void(0);')
            .removeClass('magnific')
            .css('cursor', 'move');
    });
});

/**
 * Links
 */
$(() => {
    $('.link').on('click', (e) => {
        const el = $(e.currentTarget);
        if (el.data('href') !== '') window.document.location = el.data('href');
    });
});

/**
 * Save reordered items of sortable
 */
$(() => {
    $('.save-reorder').on('click', (e) => {
        e.preventDefault();

        const el = $(e.currentTarget);
        // disable button to ban multiple clicks
        el.attr('disabled', 'disabled');

        el.html('Ukladám poradie fotografií...');

        $('ul.photos-list').sortable('destroy');

        $('#reorder-helper')
            .after(
                '<div class="alert alert-info">Ukadanie poradia môže chvíľu trvať. Prosím, neodchádzajte z tejto stránky, kým sa nanovo nenačíta.</div>'
            )
            .hide();

        const ids: string[] = $('li.sortable-item')
            .map((i, e) => <string>$(e).attr('id') ?? '')
            .toArray();

        $.ajax({
            type: 'post',
            url: el.data('href'),
            data: {
                _token: $('#csrf-token').attr('content'),
                ids: ids,
            },
            success: () => {
                window.location.reload();
            },
            error: () => {
                alert('Poradie sa nepodarilo uložiť.');
            },
        });
        return false;
    });
});

/**
 * Cart
 */
$(() => {
    $(document).on('click', '.cart-action', (e) => {
        e.preventDefault();

        const el = $(e.currentTarget);
        let action = el.data('action');
        let type = el.data('type');

        $.ajax({
            type: action,
            url: el.data('href'),
            data: {
                _token: $('#csrf-token').attr('content'),
            },
            success: (response) => {
                notification(
                    response.message,
                    response?.status === 'ok' ? 'success' : 'danger'
                );

                $.ajax({
                    type: 'get',
                    url: '/sk/cart',
                    success: (response) => {
                        $('#offcanvas-cart').html(
                            $(response).children('div.inner')[0]
                        );
                        $('#cart-button span').text(
                            $('.minicart-product-list > li').length
                        );
                    },
                    error: () => {
                        notification(
                            'Nepodarilo sa aktualizovať košík',
                            'danger'
                        );
                    },
                });
            },
            error: () => {
                notification(
                    'Došlo k chybe pri operácii s košíkom. Nanovo načítajte stránku a skúste vykonať akciu znovu',
                    'danger'
                );
            },
        });
        return false;
    });
});

/**
 * CKEditor
 */
// $(() => {
//     // @ts-ignore
//     if (typeof ClassicEditor !== 'undefined') {
//         // @ts-ignore
//         ClassicEditor.create(document.querySelector('.ckeditor'), {
//             language: 'sk',
//             height: 500,
//         }).catch((error: any) => {
//             console.error(error);
//         });
//     }
//     // @ts-ignore
//     else if (typeof InlineEditor !== 'undefined') {
//         // @ts-ignore
//         InlineEditor.create(document.querySelector('.ckeditor-inline'), {
//             language: 'sk',
//             floatSpacePinnedOffsetY: 20,
//             // @ts-ignore
//         }).then((editor) => {
//             console.log(Array.from(editor.ui.componentFactory.names()));
//         }).catch((error: any) => {
//             console.error(error);
//         });
//     }
// });

$(() => {
    let linesToDisplay = 7;

    $('#pricelist table').each((i, el) => {
        let table = $(el);
        let numberOfCoumns = table.find('tr').length;

        if (numberOfCoumns > linesToDisplay){
            table.attr('id', `prctbl-${i}`);
            table.find(`tr:gt(${linesToDisplay})`).hide();
            table.after(`<div class="text-center"><button class="btn btn-primary show-more" data-target="#prctbl-${i}">Zobraziť celý cenník</button></div>`)
        }
    });

    $('#pricelist .show-more').on('click', (e) => {
        let button = $(e.delegateTarget);
        button.hide();
        $(`${button.data('target')} tr:gt(${linesToDisplay})`).slideDown();
    });
})
